<template>
  <div class="affiche-page">
    <!-- 事业单位网上报名页面 -->
    <!-- 主要内容 -->
    <div class="main-content">
      <!-- 右边分页 、 搜索 -->
      <div class="rigth-paging">
        <!-- 标题 -->
        <h4 class="contact-title">事业单位网上报名</h4>

        <!-- 搜索框 -->
        <div class="search-box">
          <input
            type="text"
            placeholder="请输入关键字搜索相关公告"
            class="input-btn"
            v-model="searchKey"
          />
          <div class="search-btn" @click="search">搜 索</div>
        </div>
        <!-- <component
          ref="comp"
          :is="componentName"
          :ywType="ywType"
          :searchKey="searchKey"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
// import announcementList from "@/components/announcementList.vue";
// import Menu from "@/components/menu/index.vue";
export default {
  name: "Affiche",
  // components: {
  //   Menu,
  // },

  data() {
    return {
      // 动态组件名
      searchKey: "",
      // 右边内容显示标题
      contactTitle: this.$route.query.ywType,
      ywType: "招聘公告",
    };
  },
  watch: {
    $route() {
      this.init(
        this.$route.query.title,
        this.$route.query.ywType,
        this.$route.query.index
      );
    },
  },
  created() {
    //this.contactTitle = this.$route.query.title;
  },
  mounted() {
    // this.init(
    //   this.$route.query.title,
    //   this.$route.query.ywType,
    //   this.$route.query.index
    // );
  },
  methods: {
    search() {
      this.$refs.comp.searchAction();
    },
    init(e, b, c) {
      console.log(c, "c");
      this.contactTitle = e;
      this.ywType = b;
      this.$refs.comp.getPagingtList();
      this.selected(c);
    },
  },
};
</script>

<style scoped>
.affiche-page {
  width: 100%;
  background-color: #ffffff;
}

.affiche-top {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 16px 0px;
}

.navigation-btn {
  width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  opacity: 1;
}

.title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #e01811;
  opacity: 1;
}

.main-content {
  width: 100%;
  /* height: 800px; */
  display: flex;
}

.left-sidebar {
  width: 20%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  padding: 32px;
  /* box-sizing: border-box; */
  margin-right: 32px;
  user-select: none;
}

.btn-item {
  height: 40px;
  color: #333333;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.red {
  color: #ffffff;
  background: #e01811;
}

.pink {
  color: #e01811;
  background: #ffbebc;
}

.btn-item.active {
  color: #e01811;
  background: #ffbebc;
}

.rigth-paging {
  flex: 1;
  height: 100%;
  padding: 32px;
  background: #ffffff;
  box-sizing: border-box;
}

.contact-title {
  margin: 0px;
  font-size: 24px;
  font-weight: 600;
  color: #e01811;
}

.search-box {
  width: 100%;
  height: 50px;
  display: flex;
  background: #f5f5f5;
  margin-top: 16px;
  margin-bottom: 40px;
}

.input-btn {
  height: 100%;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  background: none;
  border: none;
  flex: 1;
  margin-left: 32px;
}

.search-btn {
  width: 131px;
  height: 100%;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  background: #e01811;
  text-align: center;
  line-height: 56px;
  cursor: pointer;
}
</style>